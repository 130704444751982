
import Http from "~~/utils/http";
/**
 * 获取首页信息流
 *  
 */
 export const getIndex = () => {
    return Http.get('api/index')
}

/**
 * 站点配置
 */
 export const getConfig = () => {
    return Http.get('api/config')
}

/**
 * 获取导航菜单
 */
 export const getMenus = (params?: any) => {
    return Http.get('api/columns', params)
}

/**
 * 获取首页幻灯
 */
 export const getSlider = (params?: any) => {
    return Http.get('api/slider', params)
}
/**
 * 景区
 */
 export const getSpecial = (params?: any) => {
    return Http.get('api/special',params)
}
/**
 * 友情链接
 */
 export const getFriendLinks = () => {
    return Http.get('api/friendlinks')
}
/**
 * 文章列表
 */
 export const getContent = (params?: any) => {
    //console.log(params)
    return Http.get('api/contents', params)
}
/**
 * 推荐幻灯列表
 */
 export const getContentSlider = (params?: any) => {
    return Http.get('api/contents/sliders', params)
}
/**
 * 文章详情
 */
 export const getContentDetail = (params?: { id?: number,type?: string}) => {
    return Http.get('api/contents/'+ params.id, params)
}
/**
 * 搜索
 */
 export const getSearch = (params?: { page_size?: number, page?: number}) => {
    return Http.get('api/search', params)
}
/**
 * 发展历程
 */
 export const getCourse = (params?: { page_size?: number, page?: number}) => {
    return Http.get('api/overview/course', params)
}
/**
 * 发展规划
 */
 export const getPlanning = (params?: { page_size?: number, page?: number}) => {
    return Http.get('api/overview/planning', params)
}
/**
 * 产业结构
 */
 export const getIndustrual = (params?: { page_size?: number, page?: number}) => {
    return Http.get('api/overview/industrial', params)
}
/**
 * 旗下企业
 */
 export const getSubsidiary = (params?: { page_size?: number, page?: number}) => {
    return Http.get('api/overview/subsidiary', params)
}
/**
 * 企业荣誉
 */
 export const gethonor = (params?: { page_size?: number, page?: number}) => {
    return Http.get('api/overview/honor', params)
}
/**
 * 联系我们
 */
 export const getContact = (params?: { page_size?: number, page?: number}) => {
    return Http.get('api/contact/subsidiary', params)
}

/**
 * 标签列表
 */
export const getTags = (params?:any) => {
    return Http.get('api/tags',params)
}

/**
 * 浏览记录
 */
export const pageView = (body?:any) => {
    return Http.post('api/contents/pageview',body)
}

/**
 * 公共函数
 */
export const replaceString = (params?: any)=>{
    if(params.str){
        const str = params.str.replaceAll('&nbsp;','')
        return str.replaceAll('/storage/',params.API_BASE+'/storage/')
    }
}
export const getPageName = (type?: string) => {
    switch (type){
        case 'product':
            return 'products'
        case 'scenic':
            return 'attractions'
        case 'image':
            return 'pictures'
        default:
            return 'detail'
    }
}