import { _AsyncData } from "nuxt/dist/app/composables/asyncData";
import { hash } from 'ohash'

//指定后端返回的基本数据类型
export interface ResponseCofing {
    code: number,
    status: number,
    data: any,
    msg: string
}

export interface ValueConfig {
    value: any,
    [x: string]: any
}

const fetch = (url: string, options?:  any): Promise<any> => {
    const { $config, $router } = useNuxtApp()
    const reqUrl = $config.public.API_BASE + url
    //const { public: { API_BASE } } = useRuntimeConfig() // 3.0正式版环境变量要从useRuntimeConfig里的public拿
   // const reqUrl = API_BASE + url // 你的接口地址
    // 不设置key，始终拿到的都是第一个请求的值，参数一样则不会进行第二次请求
    const key = hash(JSON.stringify(options) + url)
    return new Promise((resolve, reject) => {
        useFetch(reqUrl, {...options,key}).then(({data, error}: _AsyncData<any,any>) => {
            
            if(error.value) {
                reject(error.value)
                return
            }
            const value = data.value

            if(value.meta){
                resolve(ref<any>(value))
            }

            let result = value && value.data
           
            if(!result || value.code !== 200){
                //这里处理错误回调
                resolve(ref<any>(result))
            } else {
                resolve(ref<any>(options.method === 'get' ? result : value))
            }
        }).catch((err: any) => {
            debugger
            console.log(err)
            reject(err)
        })
    })
}

export default new class Http {
    
    get(url: string, params?: any): Promise<any> {
        return fetch(url, { method: 'get', params })
    }

    post(url: string, body?: any): Promise<any> {
        return fetch(url, { method: 'post', body })
    }

    put(url: string, body?: any): Promise<any> {
        return fetch(url, { method: 'put', body })
    }

    delete(url: string, body?: any): Promise<any> {
        return fetch(url, { method: 'delete', body })
    }
}